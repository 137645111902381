import { BaseSubPage } from "../components/BaseSubPage";
import { DataTile } from "../components/DataTile";
import { SubPageHeader } from "../components/SubPageHeader"
import { useStore } from "../stores/caseStore";


const historyDataTiles = [
    {
        title: 'Operation i livmoderen',
        description: 'Aarhus Universitetshospital 2019',
        details: <div className="mt-3"> <p> Fjernelse af fibrom / muskelknude </p> <p className="text-left"> Ingen videre komplikationer </p> </div>
    },
    {
        title: 'Keglesnitsoperation',
        description: 'Aalborg Universitetshospital 2010',
    },
    
];

const HistoryContent = () => { 

    const { activeCase } = useStore();

    return  (<div className="">
                <div className="gap-1 flex flex-col mb-6">
                    <h2 className='merriweather-bold text-xl mb-0'>Om sygeshitorik</h2>
                    <div className="text-left"><p>Din sygdomshistorik kan have indflydelse på din graviditet. Se mere herunder.
                    </p></div>
                </div>
                {activeCase.healthHistory}
            </div>)
}

export const History = () => { 


    return(
        <BaseSubPage title={"Sygehistorie"} > 
            <HistoryContent/>
        </BaseSubPage>
    )
}