import { CaretLeft } from "@phosphor-icons/react"
import { Link } from "react-router-dom"


export const SubPageHeader = ({title, classNames}) => { 
    return (
        <div className='flex flex-row flex-1 items-center align-center gap-3 bg-orange-50 p-6'>
        < Link to={"/data"}>
                <CaretLeft size={30} weight='regular'/>
            </Link>
            <h1 className="merriweather-bold text-2xl">
            {title}
            </h1>
        </div>
    )  
}