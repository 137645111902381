import { SubPageHeader } from "./SubPageHeader"

export const BaseSubPage = ({title, children}) => { 

    return(
        <>
            <div className='flex flex-row items-start justify-start'>
                <SubPageHeader classNames="mb-0" title={title} />
            </div>
            <div className='flex flex-1 flex-col p-6 overflow-auto'> 
                {children}
            </div>
    </>
    )
}