import { Folder } from "@phosphor-icons/react";
import { DataTile } from "../components/DataTile";
import { userStore } from "../stores/userStore";
import { useStore } from "../stores/caseStore";
import { useEffect, useRef } from "react";


export const Data = () => { 

    const { showHistory, showBirths, pinnedTiles } = userStore();
    const { tiles, scrollPosition, setScrollPosition } = useStore(); ;

    const tileListRef = useRef();


    const tilesWithPinnedProp = tiles?.map(tile => {
        
        tile.isPinned = pinnedTiles.includes(tile.id);

        return tile;

     }); 

    const sortedDataTiles = tilesWithPinnedProp.sort((a, b) => { 
        if(a.isPinned) return -1;
        if(b.isPinned) return 1;
        return 0;
    });

    useEffect(() => { 
        window.scrollTo(0, scrollPosition);
    }, [])

    const handleScroll = () => {
        setScrollPosition(window.scrollY)
      }


    return (
        <div>
              <div className='flex flex-row items-center align-center gap-3 bg-orange-50 p-6'>
                <Folder size={30} weight='regular'/> 
                <h1 className="merriweather-bold text-3xl">Graviditetsmappen </h1>
            </div>
            <div className=' flex flex-1 flex-col items-start justify-start gap-3 p-6'>
                <h2 className='pt-4 merriweather-bold text-xl'>Min graviditet</h2>
                <p className='pb-4'>Her kan du se et overblik over dine data samt kontaktoplyser i graviditeten. 
                </p>
            <div>
                <div ref={tileListRef} className="flex flex-wrap gap-3">
                    <DataTile key={"profile"} title={"Mine data"} description={"Lav dine personlige indstillinger"} path={"/"}  enablePinning={false} inverted />
                    {sortedDataTiles.map((tile, index) => { 
                        if(tile.id === 5 && !showBirths) return null
                        if(tile.id === 3 && !showHistory) return null
                        return <DataTile  onTileClick={handleScroll} key={index} title={tile.title} description={tile.description} path={tile.path} icon={tile.icon} details={tile.details} isPinned={tile.isPinned} id={tile.id} enablePinning />
                    })}
                </div>
            </div>
        </div>
      </div>
        
    )
}