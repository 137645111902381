import { useState } from 'react';
import image from '../assets/mock-weight-graph.png';
import { BaseSubPage } from '../components/BaseSubPage';
import { useStore } from '../stores/caseStore';


const activeColumnTypes = {
    WEIGHT:'WEIGHT',
    LIFE: 'LIFE'
};

export const CurrentData = () => { 
    const [showGraph, setShowGraph] = useState(false);
    const {activeCase} = useStore ();

    const [activeColumnType, setActiveColumnType] = useState(activeColumnTypes.WEIGHT);




    return(
        <BaseSubPage title={"Løbende data"}>
            <div className='flex flex-col'>
                <p className="mb-4">Her kan du løbende, igennem din graviditet, følge med i barnets udvikling ligeledes du også kan se dine egne data. 
                </p>
                <h5 className="merriweather-bold">Værdier for dig</h5>
                <p className="mb-4">Blodtryk: {activeCase.bloodPressure}</p>
                <h5 className="merriweather-bold mb-4">Værdier for barnet</h5>

            </div>
           
            <div className='flex flex-col' >
                <div className='h-[338px]'>
             {
                !showGraph && (
                    <div> 
                <table>
                <thead>
                    <tr>
                    <th scope="col"> <span className='text-white'>Uge</span></th>
                    <th scope="col"> 
                    <select onChange={(e)=> setActiveColumnType(e.target.value)} className='bg-[#740037] max-w-[230px] text-white'>
                        <option value={activeColumnTypes.WEIGHT}>Vægt</option>
                        <option value={activeColumnTypes.LIFE}>Liv</option>
                    </select>     
                    </th>
                    </tr>
                </thead>
                <tbody className='bg-white'>
                    {
                        activeCase?.currentData?.map((dataRow, index) => { 
                            return (    
                                <>
                                <tr>
                                    <th scope="row">{dataRow.week}</th>
                                    {activeColumnType === activeColumnTypes.WEIGHT ? <td>{dataRow.weight}</td> :  <td>{dataRow.life}</td>}
                                </tr>
                            </>
                            )
                        })
                    }
                </tbody>
                <tfoot>
                    <tr>
                    </tr>
                </tfoot>
                </table>

            </div>)
                }
             {
                activeColumnType === activeColumnTypes.WEIGHT && (
                    <><div className='bg-[#740037] text-white p-2 font-bold mt-10'>Graf over vægt i gram</div>
                    <img alt="graph" src={activeCase.graph}/>
                    </>
                )
             }
             </div>
               <div className="flex flex-row justify-center">
                </div>
                </div> 
           
        </BaseSubPage>
    )

}