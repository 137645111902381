import { BaseSubPage } from "../components/BaseSubPage"
import { useStore } from "../stores/caseStore";

export const Midwife = () => { 

    const { activeCase } = useStore();

    return(

            <BaseSubPage title="Jordemoder"> 
                <div className="gap-2 flex flex-col">
                    <h2 className='pt-8 merriweather-bold text-xl mb-2'>Information om jordemoder</h2>
                    <div className="text-left"><p>Navn: {activeCase?.midwife?.name}</p></div>
                    <div className="text-left"><p>Ønsket jordemoder: {activeCase?.midwife?.requestedMidwife}</p></div>
                    <div className="text-left"><p>Ønsker forberedende undervisning: {activeCase?.midwife?.education}</p></div>
                    <div className="text-left"><p>Adresse: {activeCase?.midwife?.address}</p></div>
                    <div className="text-left"><p>Telefon: {activeCase?.midwife?.phone}</p></div>
                </div>
            </BaseSubPage>
    )

}