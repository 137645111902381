import { Route, Routes } from 'react-router-dom';
import './App.css';
import { Data } from './views/Data';
import { Profile } from './views/Profile';
import { CurrentData } from './subView/CurrentData';
import { Placenta } from './subView/Placenta';
import { Births } from './subView/Births';
import { Midwife } from './subView/Midwife';
import { History } from './subView/History';
import { Memory } from './subView/Memory';
import { useEffect, useState } from 'react';
import { useStore } from './stores/caseStore';
import Cookies from 'universal-cookie';

import Graph1 from "../src/assets/graphs/graph1.png";
import Graph2 from "../src/assets/graphs/graph2.png";
import Graph3 from "../src/assets/graphs/graph3.png";
import Graph4 from "../src/assets/graphs/graph4.png";
import Graph5 from "../src/assets/graphs/graph5.png";
import { Fetus } from './subView/Fetus';


export const placentaPlacements = { 
  normal: 'normal',
  partialFront: 'partialfront',
  front: 'front',
};

const cases = {
  1:{
    id: 1,
    previousBirths: [],
    healthHistory: "Kendt med angst – velbehandlet, ingen medicin i graviditeten",
    placentaDescription: "På bagvæg, i toppen af livmoderen",
    placentaPlacement:  placentaPlacements.normal,
    bloodPressure: "120/76",
    graph: Graph1,
    currentData: [
    {
      week: "25+0", 
      weight: "",
      life: "mærker dgl liv",
    },
    {
      week: "28+3",
      weight: "1200",
      life: "mærker dgl liv",
    },
    {
      week: "32+0",
      weight: "2000",
      life: "mærker dgl. liv"
    },
    {
      week: "35+3",
      weight: "2500",
      life: "mærker dgl.liv"
    }],
    birthLocation: "Viborg",
    ul: "18/10-2024",
    healthVisitor: {
        name: "Jette Bering",
        phone: "20311245",
        address: "Sundhedsplejen i Viborg kommune"
    },
    midwife: {
        name: "Lotte Fisker",
        address: "Regionshospitalet Viborg, Toldbodgade 12M, 8800 Viborg",
        requestedMidwife: "",
        education: "",
        midwifeCenter:  "He Midt, Viborg"
    },
    fetusPlacement: "Hovedstilling",
  },
  2: {
    id: 2,
    previousBirths: [],
    healthHistory: "Sund og rask",
    placentaDescription: "På forvæg",
    placentaPlacement: placentaPlacements.normal,
    bloodPressure: "111/67",
    graph: Graph2,
    currentData: [
      {
        week: "25+5", 
        weight: "",
        life: "Mærker liv som vanligt"
      },
      {
        week: "29+1",
        weight: "1400g",
        life: "dagligt liv."
      },
      {
        week: "33+5",
        weight: "2000g",
        life: "Mærker liv som vanligt",
      }
    ],
    birthLocation: "Regionshospitalet Gødstrup",
    ul: "10.12.2024",
    healthVisitor: {
      name: "Sofie Hansen",
      address: "Torvet 5, 7400 Herning",
      phone: "12345678"
    },
    midwife: {
        name: "Jordemoderklinikken",
        address: "Hospitals Parken 15, 7400 Herning",
        requestedMidwife: "Christina Østergaard",
        education: "Ja",
        midwifeCenter:  "Jordemoder klinikken"
    },
    fetusPlacement: "Hovedstilling",
  },
  3: {
    id: 3,
    previousBirths: [{
      complications: "ammeproblemer",
      year: "2021",
      age: "40+2",
      birthLocation: "Regionshospitalet Gødstrup",
      experience: "Fin, men lang",
      pregnancyNotes: "Havde kvalme",
      birthNotes: "Det var en god oplevelse, men der var lang latensfase."
    }],
    healthHistory: "Ingen",
    placentaDescription: "I fundus",
    placentaPlacement: placentaPlacements.front,
    bloodPressure: "125/75",
    graph: Graph3,
    currentData: [
      {
        week: "25+3", 
        weight: "Vægt?",
        life: "Mærker liv"
      },
      {
        week: "29+6",
        weight: "1500g",
        life: "Mærker liv som vanligt"
      },
      {
        week: "32+1",
        life: "dgl. liv",
        weight: "Vægt?",
      },
      {
        week: "36+2",
        life: "Mærker liv som vanligt",
        weight: "2650g",
      }
    ],
    birthLocation: "Regionshospitalet Gødstrup",
    ul: "10.05.2021",
    healthVisitor: {
      name: "Tarm sundhedsplejersken",
      address: "Rådhuset Ringkøbing/Skjern",
      phone: "12345678"
    },
    midwife: {
        name: "Jordemoder Klinikken, Tarm",
        address: "Jordemoder Klinikken, Tarm",
        requestedMidwife: "Louisa Daater",
        education: "n",
        midwifeCenter:  "Jordemoder klinikken"
    },
    fetusPlacement: "Hovedstilling",
  },
  4: {
    id: 4,
    previousBirths: [{
      complications: "Post partum blødning på 1 l",
      year: "2022",
      age: "40+6",
      birthLocation: "Regionshospitalet Gødstrup",
      experience: "Nogenlunde, lidt voldsom i forbindelse med blødningen",
      pregnancyNotes: "",
      birthNotes: ""
    }],
    healthHistory: "Mb. Chron",
    placentaDescription: "Højt forvæg",
    placentaPlacement: placentaPlacements.normal,
    bloodPressure: "125/67",
    graph: Graph4,
    currentData: [
      {
        week: "25+3", 
        weight: "?",
        life: "Dagligt liv"
      },
      {
        week: "29+5",
        weight: "1500g",
        life: "Dagligt liv"
      },
      {
        week: "32+1",
        life: "dgl. liv",
        weight: "?",
      },
      {
        week: "35+5",
        life: "Dagligt liv",
        weight: "2600g",
      }
    ],
    birthLocation: "Regionshospitalet Gødstrup",
    ul: "22.09.2024",
    healthVisitor: {
      name: "Ikast Kommune",
      address: "Rådhuset Ikast",
      phone: "12345678"
    },
    midwife: {
        name: "Team Ikast",
        address: "Ellehammervej 4",
        requestedMidwife: "Louisa Daater",
        education: "Ja",
        midwifeCenter:  "Jordemoder klinikken"
    },
    fetusPlacement: "Hovedstilling",
  },
  5: {
    id: 5,
    previousBirths: [{
      complications: "gode amme erfaringer",
      year: "2019",
      age: "41+2",
      birthLocation: "He Midt- Viborg",
      experience: "2 gode fødsler",
      pregnancyNotes: "",
      birthNotes: "føder hurtigt"
    },
    {
      complications: "gode amme erfaringer",
      year: "2022",
      age: "40+5",
      birthLocation: "Hjemme",
      experience: "2 gode fødsler",
      pregnancyNotes: "",
      birthNotes: "føder hurtigt"
    }],
    healthHistory: "2023 Spontan abort uge 7",
    placentaDescription: "I toppen af livmoderen",
    placentaPlacement: placentaPlacements.front,
    bloodPressure: "115/80",
    graph: Graph5,
    currentData: [
      {
        week: "25+3", 
        weight: "900g",
        life: "Dagligt liv"
      },
      {
        week: "29+5",
        weight: "1500g",
        life: "Dagligt liv"
      },
      {
        week: "32+1",
        life: "dgl. liv",
        weight: "2600",
      },
      {
        week: "36+4",
        life: "mærker dgl liv",
        weight: "2900g",
      }
    ],
    birthLocation: "hjemme",
    ul: "11/10-2024",
    healthVisitor: {
      name: "",
      address: "Silkeborg kommune",
      phone: "12345678"
    },
    midwife: {
        name: "jordemoder klinikken, Silkeborg",
        address: "",
        requestedMidwife: "",
        education: "",
        midwifeCenter:  "He – midt, Viborg"
    },
    fetusPlacement: "Hovedstilling/ verificeret ved Ul scanning",
  }
}


function App() {

  const { setActiveCase, activeCase} = useStore();
  const [showCaseChooser, setShowCaseChooser] = useState(false);

  const cookies = new Cookies ();
  
  useEffect(() => {
    setActiveCase(cookies.get('case') ? cases[cookies.get('case')] : cases[1])
  },[])

  const changeCase = (e) => { 
      setActiveCase(cases[e.target.value])
      setShowCaseChooser(false);
      cookies.set('case',e.target.value , { path: '/' });
  }

  return (
      <div className="flex flex-col flex-1 min-h-0">
          <Routes>
            <Route path="/" element={<Profile />} /> 
            <Route path="/data" element={<Data/>} /> 
            <Route path="/data/currentData" element={<CurrentData/>} /> 
            <Route path="/data/placenta" element={<Placenta/>} /> 
            <Route path="/data/midwife" element={<Midwife/>} /> 
            <Route path="/data/births" element={<Births/>} /> 
            <Route path="/data/history" element={<History />} /> 
            <Route path="/data/memory" element={<Memory />} /> 
            <Route path="/data/fetus" element={<Fetus />} /> 
        </Routes>
        
        <button onClick={()=>setShowCaseChooser(true)} className="w-full h-[80px] flex flex-col items-center justify-start">
          {
            showCaseChooser && (
              <select defaultValue={activeCase.id} className="p-2 bg-orange-50" onChange={(e)=>changeCase(e)}>
                <option value="1">Case 1 - Første gang angst</option>
                <option value="2">Case 2 - Første gang ektra scan</option>
                <option value="3">Case 3 - Andengangsfødende</option>
                <option value="4">Case 4 - Andengangs lidt kompl</option>
                <option value="5">Case 5 - Tredje gang hjemme</option>
              </select>
            )
          }
        </button>
       
      </div>
      
   
  );
}


export default App;
