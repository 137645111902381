import { BaseSubPage } from "../components/BaseSubPage"
import fetusHeadPlacement from '../assets/fetus/fetus-position.png';
import { useStore } from "../stores/caseStore";




export const Fetus = () => { 

    const { activeCase } = useStore();

    return(

        <BaseSubPage title={"Fosterstilling"}>
            <div className="flex flex-col gap-3">
                <p>Herunder kan du se dit fosters placering: </p>
                {activeCase.fetusPlacement}
                <img className="max-w-[500px] max-w" alt="fetus" src={fetusHeadPlacement }/>
            </div>
               
        </BaseSubPage>

    )
}