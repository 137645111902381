import { BaseSubPage } from "../components/BaseSubPage"
import { DataTile } from "../components/DataTile"
import { useStore } from "../stores/caseStore";



export const Births = () => { 

    const { activeCase } = useStore();

    
    if(!activeCase?.previousBirths?.length){
        return(
            <BaseSubPage title={"Tidligere fødsler"}> 
                <p className="mb-4 gap-2">Du har ingen tidligere fødsler registreret.</p>
            </BaseSubPage>
        )
    }

    const birth = activeCase?.previousBirths[0];

    return(
        <BaseSubPage title={"Tidligere fødsler"}> 
            <p className="mb-4 gap-2">Her kan du se dine tidligere fødsler og noter om dem.</p>
            <h3><b>Noter</b></h3>
    
            <p className="mb-10">{birth.experience}  {birth.birthNotes}</p>

            <div className="flex flex-col gap-3">
            <h3><b>Fødsler</b></h3>
                {
                    
                    activeCase?.previousBirths?.map((birth)=> {

                        const title = birth.year + " " + birth.age;

                        const description = birth.birthLocation;
                        
                        return (<DataTile title={title} description={description}/>)
                    })  
                    
                }
            </div>
        </BaseSubPage>
    )
}