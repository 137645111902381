import { BaseSubPage } from "../components/BaseSubPage";
import { useStore } from '../stores/caseStore';
import placentaNormal from '../assets/placenta/placenta-normal.png';
import placentaFront from '../assets/placenta/placenta-total-front.png';
import placentaPartialFront from '../assets/placenta/placenta-partial-front.png';
import { placentaPlacements } from "../App";



const PlacentaImage = ({ placentaPlacement}) => { 

    let imagePath = placentaNormal;
    if(placentaPlacement === placentaPlacements.front){ 
        imagePath = placentaFront;
    } else if(placentaPlacement === placentaPlacements.partialFront){ 
        imagePath = placentaPartialFront;
    }

    return (
    <img className="max-w-[500px] w-full" src={imagePath} alt="placenta"/>)
}

export const Placenta = () => { 

    const { activeCase } = useStore();

    return(
        <BaseSubPage title="Moderkagens placering">
            <div className="flex flex-col gap-5">
                <div> 
                    <p className='mb-10'>
                    Her kan du se, hvordan moderkagen placerer sig i løbet af graviditeten. For nogen vil den ikke flytte sig, og for andre kan placeringen skifte undervejs. 
                    </p>
                    <p className='mb-10'> 
                        Jordemoderens bekrivelse: {activeCase.placentaDescription}
                    </p>
                    <PlacentaImage placentaPlacement={activeCase.placentaPlacement}/>
                </div>
            </div>
        </BaseSubPage>
    )

}