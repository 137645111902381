import { create } from 'zustand'

const dataTiles = [
  {
      title: 'Løbende data',
      description: 'Her kan du se data fra denne graviditet',
      path: '/data/currentData',
      id: 2
  },
  {
      title: 'Sygehistorie',
      description: 'Se din sygehistorik',
      path: '/data/history',
      id: 3
  },
  {
      title: 'Moderkagens placering',
      description: 'Her kan du se information din moderkage',
      path: '/data/placenta',
      id: 4
  },
  {
    title: 'Fosterstilling',
    description: 'Se hvordan dit foster ligger',
    path:'/data/fetus',
    id: 11
  },
  {
      title: 'Tidligere fødsler',
      description: 'Her kan du se dine tidligere fødsler',
      path: '/data/births',
      id: 5
  },
  {
      title: 'Fødested',
      description: 'Horsens sygehus',
      id: 6
  },
  {
      title: 'Ultralydsfastsat temin',
      description: '26/11/2024',
      id: 7
  },
  {
      title: 'Sundhedsplejerske',
      description: 'Britta Hansen',
      details: <div className="text-left"><p>Telefon: 89045231</p> <p>Adresse: Nøregade 33</p><p>Træffetider: 8-16 man-ons</p></div>,
      id: 8
  },
  {
      title: 'Jordemoder',
      description: 'Hanne Bertelsen',
      path:'/data/midwife',
      details: <div className="text-left"><p>Telefon: 89045234</p> <p>Adresse: Vestgade 18</p><p>Træffetider: 8-16 man-ons</p></div>, 
      id: 9
  },
  {
      title: 'Mit graviditetsminde',
      description: 'Se og udskriv dit barselsminde',
      path:'/data/memory',
      id: 10        
  }
];

const mapTilesWithCaseData = (tiles, caseData) => { 

  const tilesWithCaseData = tiles.map(tile => { 
    
    if(tile.id === 6) {
      tile.description = caseData?.birthLocation;
      return tile;
    }
    else if(tile.id === 7) {
      tile.description = caseData?.ul;
      return tile;
    }
    else if(tile.id === 8) {
      tile.description = caseData?.healthVisitor?.name;
      tile.details = <div className="text-left"> <p>Adresse: {caseData?.healthVisitor?.address}</p><p>Jordemoderklinik: {caseData.healthVisitor.midwifeCenter}</p></div>;
      return tile;
    }
    else if(tile.id === 9) {
      tile.description = caseData?.midwife?.name;
      tile.details = <div className="text-left"> <p>Adresse: {caseData?.midwife?.address}</p></div>;
      return tile;
    }  
     else {
      return tile
    }
  
  });
  return tilesWithCaseData;
}


const handleChangeCase = (newActiveCase, set) => {
  set(() => ({ activeCase: newActiveCase }))
  const mappedTiles = mapTilesWithCaseData(dataTiles , newActiveCase);
  set(() => ({ tiles: mappedTiles }))

}

export const useStore = create((set) => ({
  count: 1,
  inc: () => set((state) => ({ count: state.count + 1 })),
  activeCase: false,
  setActiveCase: (newActiveCase)=>handleChangeCase(newActiveCase, set),
  tiles: [],
  setTiles: (newTiles) => set(() => ({ tiles: newTiles })),
  scrollPosition: 0,
  setScrollPosition: (pos) => set({ scrollPosition: pos }),
}))
